/* Variables */
$left-menu-background-color: #273a4a;
$left-menu-background-color-offset: #233442;
$left-menu-border-color: #445362;
$left-menu-font-color: #949faf;
$left-menu-hover-color: #fff;
$menu-icon-light-color: #999c9e;
$menu-link-light-color :rgba(0, 0, 0, .87);
$keefe-green: #00a652;

.isVisible {
  display: inline;;
}

.isHidden{
  display: none;
}

span.vertical-line {
  border-right: 1px solid $left-menu-border-color;
  position: absolute;
  height: 45px;
  left: 53px;
}

div#progress-bar {
  position: absolute;
  top: 45%;
  left: 47%;
}

div#uniNav-top-nav {
  header {
    color: #fff;
  }

  svg {
    color: $menu-icon-light-color;
  }

  .uniNav-header-text {
    color: $menu-icon-light-color;
    text-transform: capitalize;
    font-size: 16px !important;
  }
}

.uniNav-menu-title.uf-MuiListItemText-inset {
  padding-left: 0px;
}

.uniNav-menu-title span {
  font-size: 14px !important;
}


#menu-appbar-admin [role='document'] {
  top: 60px !important;
  position: absolute;
}

#menu-appbar-help [role='document'] {
  top: 60px !important;
  position: absolute;
}


#menu-appbar-whats-new [role='document'] {
    top: 60px !important;
    position: absolute;
  }

#menu-appbar-user [role='document'] {
  top: 60px !important;
  position: absolute;
}

div#unifyDrawer div {
  background-color: $left-menu-background-color;
}

div#unifiyLogoContainer {
  height: 63px;
}

#unifyDrawer svg {
  color: $menu-icon-light-color;
}


.Main-drawerClose-210 {
  max-width: 57px;
}


div.left-navigation {
  div#uniNav-left-drawer div {
    background-color: $left-menu-background-color;
  }

  span.MuiListItemText-primary-31 {
    color: $menu-icon-light-color;
  }

  .logo-div {
    position: relative;
    top: 10px;
    text-align: center;
    margin-bottom: 17px;
  }

  .top-level-nav {
    border-bottom: 1px solid $left-menu-border-color;
    border-top: 1px solid $left-menu-background-color-offset;
    padding: 0;

    .uniNav-header-text {
      text-transform: capitalize;
      font-size: 16px !important;
    }
  }

  .fa {
    color: $left-menu-font-color;
    font-size: 20px;
    min-width: 25px;
  }

  i.fa.fa-chevron-right.unify {
    font-size: 12px;
  }

  .uniNav-menu-title span {
    font-size: 14px !important;
    color: $left-menu-font-color;
  }

  .uniNav-menu-title span:hover {
    color: $left-menu-hover-color;
  }


  .uniNav-collapse {
    border-left: 2px solid $keefe-green;
    padding-left: 2px !important;
  }

  svg {
    color: $left-menu-font-color;
  }

  .MuiListItemIcon-root-20 {
    min-width: 23px;
  }

  .uniNav-collapse ul {
    padding-left: 10px;
  }

  .uniNav-sub-menu .uniNav-collapse {
    border-left: none;
  }

  ul.uniNav-sub-menu {
    padding-left: 0;
  }

  .expanded .uniNav-sub-menu {
    display: none;
  }

}

.flyout {
  position: fixed;
}

.flyout-wrapper {
  top: 5px;
  position: absolute;
}

.flyout-content {
  display: none;
  position: fixed;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  left: 73px;
  border-left: 3px #fff solid;
}

.flyout-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.flyout-content a:hover {
  background-color: #ddd;
}

.flyout:hover .flyout-content {
  display: block;
}

/*Context Menu*/

.context-menu-wrapper {
  position: fixed;
  z-index: 1000;
  background-color: #fff !important;
  left: 60px;
}

.context-menu-visible{
  display: inline;
}

.context-menu-hidden{
  display: none;
}

.mouse-pointer{
  cursor: pointer;
}

@media print {
  div#uniNav-top-nav {
    display: none !important;
  }

  div#unifyDrawer {
    display: none !important;
  }
}
